<template>
  <div style="width:100%;height:100%;overflow-y:scroll;position:relative">
    <div style="width:100%;height:55%;">
      <img style="width:100%;height:100%" src="@/assets/img/招贤纳士.png">
    </div>
    <div  style="width:100%;height:65%;">
      <h1 style="position:relative;top:11%;font-size:32px">职位类别</h1>
      <h3 style="position:relative;top:13%;color:#5C5C5C;font-size:28px">Position category</h3>
      <span style="position: relative;top: 13%;display:inline-block;width:6%;height:0.9%;background:#00A8FF"></span>
      <div style="width:65%;height:53%;position:relative;left:17.5%;top:18.5%">
        <div style="width:100%;height:50%;display:flex;">
          <span style="background: #F5FAFD;flex:1;display:flex;align-items: center;position: relative;">
            <span style="flex:1;position: relative;right:-9.5%">
              <img style="width:32%;height:30%" src="@/assets/img/研发类.png">
            </span>
            <span style="flex:1;text-align:left;font-size: 26px;color: #000000;font-weight: bold;">研发类</span>
          </span>
          <span style="background: #F5FAFD;margin-left:2%;flex:1;display:flex;align-items: center;position: relative;">
            <span style="flex:1;position: relative;right:-9.5%">
              <img style="width:32%;height:30%" src="@/assets/img/设计类.png">
            </span>
            <span style="flex:1;text-align:left;font-size: 26px;color: #000000;font-weight: bold;">设计类</span>
          </span>
          <span style="background: #F5FAFD;;margin-left:2%;flex:1;display:flex;align-items: center;position: relative;">
            <span style="flex:1;position: relative;right:-9.5%">
              <img style="width:32%;height:30%" src="@/assets/img/产品类.png">
            </span>
            <span style="flex:1;text-align:left;font-size: 26px;color: #000000;font-weight: bold;">产品类</span>
          </span>
        </div>
       <div style="width:100%;height:50%;display:flex;margin-top:2%">
          <span style="background: #F5FAFD;flex:1;display:flex;align-items: center;position: relative;">
            <span style="flex:1;position: relative;right:-9.5%">
              <img style="width:32%;height:30%" src="@/assets/img/项目管理类.png">
            </span>
            <span style="flex:1;text-align:left;font-size: 26px;color: #000000;font-weight: bold;">项目管理类</span>
          </span>
          <span style="background: #F5FAFD;margin-left:2%;flex:1;display:flex;align-items: center;position: relative;">
            <span style="flex:1;position: relative;right:-9.5%">
              <img style="width:32%;height:30%" src="@/assets/img/售前类.png">
            </span>
            <span style="flex:1;text-align:left;font-size: 26px;color: #000000;font-weight: bold;">售前类</span>
          </span>
          <span style="background: #F5FAFD;;margin-left:2%;flex:1;display:flex;align-items: center;position: relative;">
            <span style="flex:1;position: relative;right:-9.5%">
              <img style="width:32%;height:30%" src="@/assets/img/销售类.png">
            </span>
            <span style="flex:1;text-align:left;font-size: 26px;color: #000000;font-weight: bold;">销售类</span>
          </span>
        </div>
      </div>
    </div>
    <div class="containers" style="width:100%;height:100%;background: #F4F6FB; ">
      <h1 style="position:relative;top:7%;font-size:32px">在招职位</h1>
      <h3 style="position:relative;top:8%;color:#5C5C5C;font-size:28px">Position in recruitment</h3>
      <span style="position: relative;top: 8%;display:inline-block;width:6%;height:0.6%;background:#00A8FF"></span>
      <div class="job" v-for="(item,index) in data" :key="index" style="width:100%;height:70%; background: #F4F6FB;">
        <div  class="jobclass" style="width:65%;height:100%;position:relative;left:17.5%;top:6%">
          <div style="width:91%;color: #00A8FF;font-size: 18px;font-weight: bold;height:7%;text-align:left;left:4.2%;border-bottom:1px solid #E6E6E6;position:relative;top:12%">
            <img style="width:2%;margin-left:2%" src="@/assets/img/职位.png">
            <!-- <span style="margin-left:1%">{{item.job}}</span> -->
            <span style="margin-left:1%">{{item.post}}</span> 
          </div>
          <div style="width:91%;height:72%;display:flex;position:relative;top:12%;left:4.3%">
            <div style="flex:1;text-align:left">
              <span style="position:relative;top:5%;left:2%;z-index: 6;font-weight: bold;">职位描述</span>
              <span style="display:block;width:11.5%;background: #FFC600;height:1%;position:relative;top:3.2%;left:2%"></span>
              <!-- <div v-for="(items,ind) in item.describeList" :key="ind" style="line-height:26px;font-size: 16px;font-weight: bold;margin-left:2%;position:relative;top:6%;color:#666666;margin-right:5%;margin-top:2%">
                {{ind+1}}、 {{items.text}}
              </div> -->
							<div style="line-height:26px;font-size: 16px;font-weight: bold;white-space: pre-wrap;margin-left:2%;position:relative;top:6%;color:#666666;margin-right:5%;margin-top:2%">
							  {{item.description}}
							</div>
            </div>
             <div style="flex:1;text-align:left;position: relative">
              <span style="position:relative;top:5%;left:2%;z-index: 6;font-weight: bold;">职位要求</span>
              <span style="display:block;width:11.5%;background: #FFC600;height:1%;position:relative;top:3.2%;left:2%"></span>
              <!-- <div v-for="(cont,inds) in item.requirementList" :key="inds" style="line-height:26px;font-size: 16px;font-weight: bold;margin-left:2%;position:relative;top:6%;color:#666666;margin-right:5%;margin-top:2%">
                {{inds+1}}、 {{cont.text}}
              </div> -->
							<div style="line-height:26px;font-size: 16px;font-weight: bold;white-space: pre-wrap;margin-left:2%;position:relative;top:6%;color:#666666;margin-right:5%;margin-top:2%">
							  {{item.requirements}}
							</div>
              <el-button @click="joinwe()" style="background: #00A8FF;border-radius: 24px;color: #FFFFFF;width:23%;position:absolute;bottom:7%;left:-14%">加入我们</el-button>
            </div>
          </div>
        </div>
      </div>
       <Footers style="width:100%;height:27%;position:relative;margin-top:-1%"></Footers>
    </div>
    <el-dialog v-model="dialogVisible"   align-center title="加入我们" width="500">
      <div style="text-align:left;margin-left:4%;margin-top:3%;font-size:16px;color:#000">
        <el-icon style="vertical-align: middle;font-size:16px;color:#000">
            <Phone />
          </el-icon>
        &nbsp;<span>电话：</span>
        <span>029-8114 5903</span>
      </div>
        <div style="text-align:left;margin-left:4%;margin-top:3%;font-size:16px;color:#000">
           <el-icon style="vertical-align: middle;font-size:16px;color:#000"><Message /></el-icon>
         &nbsp;<span>邮箱：</span>
        <span>pushinfo@push-info.com</span>
      </div>
      <img style="margin-left:1%;margin-top:3%;width:150px;height:150px" src="../../assets/img/二维码.png">
      <div style="font-size:14px;color:#000">关注东方丝路公众号</div>
       <!-- <div  style="text-align:left;margin-left:4%">
        <span >邮箱：</span>
        <span>pushinfo@push-info.com</span>
      </div> -->
    </el-dialog>
    <!-- <div style="position:absolute;left:40%;top:30%;border-radius: 20px;width:20%;height:41%;background:#fff;">
      <div style="width:100%;height:15%;text-align: center;border-radius: 20px 20px 0px 0px;background: #F2F9FC;color: #000000;line-height: 52px;font-size: 20px;font-weight: bold;">
        <span style=";position:relative;left:8%;z-index:66">加入我们</span>
        <div style="display:inline-block;width:20%;height:19%;background: #FFC600;position:relative;top:13%;left:-12.5%"></div>
      </div>
    </div> -->
  </div>
</template>

<script src="./js/RecruitPage.js">
</script>

<style src="./css/RecruitPage.css" scoped>

</style>