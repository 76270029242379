import {ref,reactive,onMounted,getCurrentInstance,defineComponent } from 'vue'
//首页
import HomePage from '../../HomePage/HomePage.vue'
//解决方案
import SolutionPage from '../../SolutionPage/SolutionPage.vue'
//产品中心
import ProductCenter from '../../ProductCenter/ProductCenter.vue'
//招贤纳士
import RecruitPage from '../../RecruitPage/RecruitPage.vue'
//公司简介
import CompanyProfile from '../../CompanyProfile/CompanyProfile.vue'
//动态资讯
import DynamicNews from '../../DynamicNews/DynamicNews.vue'
export default defineComponent({
  components: {
    HomePage,
    SolutionPage,
    ProductCenter,
    RecruitPage,
    CompanyProfile,
    DynamicNews
  },
  setup () {
    const { proxy } = getCurrentInstance();
    let activeName=ref('first')
    let productActive=ref('')
    let introActive=ref('')
    let newsActive=ref('')
    onMounted(() => {
    });
    function getproductActive(val) {
      activeName.value='third'
      productActive.value=val
    }
		function getIntroActive(val) {
		  activeName.value='fiveth'
		  introActive.value=val
		}
		function getNewsActive(val) {
		  activeName.value='sixth'
		  newsActive.value=val
		}
    function handleClick(tab,event) {
      console.log(activeName.value);
      productActive.value=''
      newsActive.value=''
    }
    return {
      proxy,
      activeName,
      productActive,
			newsActive,
      handleClick,
      getproductActive,
			getIntroActive,
			getNewsActive
    }
  }
})