<template>
  <div style="width:100%;height:100%" class="container">
    <el-container style="width:100%;height:100%;">
      <el-header style="width:100%;height:7%">
        <img src="../../../static/images/东方丝路.png" style="position:absolute;top:2%;left:3%">
        <el-tabs style="width:50%;position:relative;left:-10%;top:11%;display:inline-block" v-model="activeName" class="demo-tabs" @tab-click="handleClick">
          <el-tab-pane label="首页" name="first"></el-tab-pane>
          <el-tab-pane label="解决方案" name="second"></el-tab-pane>
          <el-tab-pane label="产品中心" name="third"></el-tab-pane>
          <el-tab-pane label="招贤纳士" name="fourth"></el-tab-pane>
          <el-tab-pane label="公司简介" name="fiveth"></el-tab-pane>
          <el-tab-pane label="动态资讯" name="sixth"></el-tab-pane>
        </el-tabs>
      </el-header>
      <el-main style="width:100%;height:80%;padding:0;margin-top: 0.5%;overflow-y: hidden;">
        <HomePage @productActive="getproductActive" @introActive="getIntroActive" @newsActive="getNewsActive" v-if="activeName=='first'"></HomePage>
        <SolutionPage v-if="activeName=='second'"></SolutionPage>
        <ProductCenter :productActive="productActive" v-if="activeName=='third'"></ProductCenter>
        <RecruitPage v-if="activeName=='fourth'"></RecruitPage>
        <CompanyProfile v-if="activeName=='fiveth'"></CompanyProfile>
        <DynamicNews :newsActive="newsActive" v-if="activeName=='sixth'"></DynamicNews>
      </el-main>
    </el-container>
  </div>
</template>

<script src="./js/Main.js">

</script>

<style src="./css/Main.css" scoped> 

</style>