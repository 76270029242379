<template>
   <div ref="scrollDiv" style="width:100%;height:100%;overflow-y:scroll;position:relative">
    <div style="width:100%;height:55%;">
      <img style="width:100%;height:100%" src="@/assets/img/动态资讯.png">
    </div>
    <div style="width:100%;height:100%">
      <ul v-if="!showDetails" style="position:relative;width:65%;height:100%;margin-left:17.5%;margin-top:3%">
        <li style="height:17.5%;width:100%;display:flex;margin-top:2%;" v-for="(item,index) in tableData" :key="index">
          <!-- <img style="flex:0.2" :src="item.img"> -->
					<div style="flex:0.25">
						<img style="width:100%; height: 100%" :src="item.imageUrl">
					</div>
          <div style="flex:1;text-align:left;border-bottom:1px solid #E6E6E6;margin-left:3%">
            <h3>{{item.title}}</h3>
            <div style="line-height:26px;margin-top:1%">{{item.content}}</div>
            <div style="margin-top:0.7%;color: #666666;">
              <img style="vertical-align: middle;" src="@/assets/img/时间.png"> 
              <!-- <span style="margin-left:1%;">{{item.time}}</span> -->
              <span style="margin-left:1%;font-size: 18px;">{{item.createdTime}}</span> 
              <!-- <span style="margin-left:5%;">来源：{{item.source}}</span> -->
              <span style="margin-left:5%;font-size: 18px;">来源：{{item.author}}</span>
              <el-button @click="clickdetails(item)" type="primary" round style="margin-left:90%;margin-top: -9%;">查看详情</el-button>
            </div>
          </div>
        </li>
      </ul>
      <div v-else style="position:relative;width:65%;margin-left:17.5%;">
          <span @click="toBack()" style="cursor:pointer;color:#409EFF;position:absolute;top:-9%;left:93%;">
          <el-icon style="vertical-align: middle">
            <Back />
          </el-icon>&nbsp;
          <span>返回</span>
        </span>
        <h1 style="margin-top:6.5%">{{activeObject.title}}</h1>
        <div style="margin-top:3%;color: #666666;">
          <img style="vertical-align: middle;" src="@/assets/img/时间.png"> 
          <!-- <span style="margin-left:1%;">{{activeObject.time}}</span> -->
          <span style="margin-left:1%;">{{activeObject.createdTime}}</span> 
          <!-- <span style="margin-left:5%;">来源：{{activeObject.source}}</span> -->
          <span style="margin-left:5%;">来源：{{activeObject.author}}</span>
        </div>
        <p style="text-indent: 2em;line-height:30px;text-align:left;margin-top:2.5%;color: #000000;">{{activeObject.content}}</p>
        <div style="width:100%;margin-top:3%;">
          <!-- <img style="width:60%;height:60%;margin-top: 2.3%;" :src="activeObject.img"> -->
          <img style="width:60%;height:60%;margin-top: 2.3%;" :src="activeObject.imageUrl">
        </div>
      </div>
    </div>
    <el-pagination v-if="!showDetails" style="text-align:center;margin-left:37%;position:relative;top:-4%" :current-page="currentPage"
	  :page-size="pageSize" :page-sizes="[1, 2, 5, 10]" :total="total" align='center'
	  layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
	  @current-change="handleCurrentChange">
	</el-pagination>
    <Footers style="width:100%;height:27%;"></Footers>
  </div>
</template>

<script src="./js/DynamicNews.js"></script>

<style src="./css/DynamicNews.css" scoped>

</style>