<template>
  <el-carousel class="my-carousel" arrow="always" indicator-position="outside">
    <el-carousel-item style="width:100%;height:100%"  v-for="(item,index) in imgList" :key="index">
      <img style="width:100%;height:100%" :src="item.url">
    </el-carousel-item>
  </el-carousel>
</template>
<script>
import {ref} from 'vue'
import url1 from '@/assets/img/轮播水务.png'
import url2 from '@/assets/img/轮播园区.png'
import url3 from '@/assets/img/轮播图交通.png'
export default {
  setup () {
    let imgList=ref([
      {
        url:url1  
      },
      {
        url:url2
      },
      {
        url:url3
      }
    ])
    return {
      imgList
    }
  }
}
</script>
<style>
.my-carousel .el-carousel__container {
  height: 100%; /* 设置为父元素高度的50% */
}
</style>