import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import locale from 'element-plus/lib/locale/lang/zh-cn'
import http from './utils/http.js'
import {ElMessage} from 'element-plus'
import {ElMessageBox} from 'element-plus'
import * as icons from '@element-plus/icons'
const app = createApp(App)
Object.keys(icons).forEach(key => {
  app.component(key, icons[key])
})
app.config.globalProperties['$message'] = ElMessage;
app.config.globalProperties['$messageBox'] = ElMessageBox;

createApp(App).use(store).use(router).use(http).use(ElMessage).use(ElMessageBox).use(ElementPlus, { locale }).mount('#app')
