import {ref,reactive,onMounted,watch} from 'vue'
import Footers from '../../Footers/Footers.vue'
import {Back} from '@element-plus/icons-vue'
import axios from 'axios'; 
export default {
  components:{
    Footers,
    Back
  },
	props: {
	  newsActive: Number
	},
  setup (props) {
    let currentPage=ref(1)
    let total=ref(0)
    let pageSize=ref(10)
		
    let tableData=ref([
      // {
      //   img:require('@/assets/img/动态资讯详情.png'),
      //   title:'行业热议数字孪生+物联网，虚实结合让实体经济找到新发力点',
      //   content:'随着疫情影响逐渐消散，经济回暖，各行各业都在抓紧时间加快数字化转型的步伐。尤其在制造领域，近十年来，我国制造业增加值从2012年的16.98万亿元增加到2021年的31.4万亿元...',
      //   time:'2023-02-16',
      //   source:'测试'
      // },
      // {
      //   img:require('@/assets/img/动态资讯详情.png'),
      //   title:'行业热议数字孪生+物联网，虚实结合让实体经济找到新发力点',
      //   content:'随着疫情影响逐渐消散，经济回暖，各行各业都在抓紧时间加快数字化转型的步伐。尤其在制造领域，近十年来，我国制造业增加值从2012年的16.98万亿元增加到2021年的31.4万亿元...',
      //   time:'2023-02-16',
      //   source:'测试'
      // },
      // {
      //   img:require('@/assets/img/动态资讯详情.png'),
      //   title:'行业热议数字孪生+物联网，虚实结合让实体经济找到新发力点',
      //   content:'随着疫情影响逐渐消散，经济回暖，各行各业都在抓紧时间加快数字化转型的步伐。尤其在制造领域，近十年来，我国制造业增加值从2012年的16.98万亿元增加到2021年的31.4万亿元...',
      //   time:'2023-02-16',
      //   source:'测试'
      // },
      // {
      //   img:require('@/assets/img/动态资讯详情.png'),
      //   title:'行业热议数字孪生+物联网，虚实结合让实体经济找到新发力点',
      //   content:'随着疫情影响逐渐消散，经济回暖，各行各业都在抓紧时间加快数字化转型的步伐。尤其在制造领域，近十年来，我国制造业增加值从2012年的16.98万亿元增加到2021年的31.4万亿元...',
      //   time:'2023-02-16',
      //   source:'测试'
      // },
      // {
      //   img:require('@/assets/img/动态资讯详情.png'),
      //   title:'行业热议数字孪生+物联网，虚实结合让实体经济找到新发力点',
      //   content:'随着疫情影响逐渐消散，经济回暖，各行各业都在抓紧时间加快数字化转型的步伐。尤其在制造领域，近十年来，我国制造业增加值从2012年的16.98万亿元增加到2021年的31.4万亿元...',
      //   time:'2023-02-16',
      //   source:'测试'
      // },
    ])
    let showDetails=ref(false)
    let activeObject=reactive({})
		let newsActives=ref(0)
		newsActives.value=props.newsActive
				
    let handleSizeChange=function(val) {
      pageSize.value = val;
      currentPage.value = 1;
			fetchData();
    }
    // 修改列表当前页
    let handleCurrentChange=function(val) {
      currentPage.value = val;
			fetchData();
    }
    function clickdetails(item) {
			console.log(item)
			let scrollElem = this.$refs.scrollDiv;
			scrollElem.scrollTo({ top: 0, behavior: 'smooth' });
      Object.assign(activeObject,item)
      showDetails.value=true
    }
    function toBack(){
      showDetails.value=false 
    }
		// 发起GET请求
		const fetchData = async () => {
		  try {
		    const response = await axios.get(`/news/queryNewestNewsForMore?currentPage=${currentPage.value}&pageSize=${pageSize.value}`);
				if (response.status === 200) {
					if (response.data.code === 200) {
						total.value = response.data.data.totalRecord
						tableData.value = response.data.data.resultSet
					} else {
						console.error(response.data.message);
					}
		    } else {
		      console.error(response.statusText);
		    }
		  } catch (error) {
		    console.error('请求失败:', error);
		  }
		};
		onMounted(() => {
		  fetchData();
		});
		watch(() => newsActives, (newValue, oldValue) => {
		  if (newValue.value!=0) {
				setTimeout(()=>{
					for (var i=0;i<tableData.value.length;i++) {
					  if (newValue.value==tableData.value[i].id) {
					    Object.assign(activeObject,tableData.value[i])
							showDetails.value=true
					  }
					}
				},500)
		  }
		},{
		  immediate: true,
		});
    return {
      total,
      currentPage,
      pageSize,
      tableData,
      showDetails,
      activeObject,
      handleSizeChange,
      handleCurrentChange,
      clickdetails,
      toBack
    }
  }
}