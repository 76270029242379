import Footers from '../../Footers/Footers.vue'
//合作伙伴
import CooperativePartner from '../../HomePage/CooperativePartner.vue'
export default {
  components:{
    Footers,
    CooperativePartner
  },
  setup() {
    
  }
}