import axios from 'axios';
import QS from 'qs';
import router from '../router';
import global from './Global'
axios.defaults.timeout = 50000;
axios.defaults.baseURL = global.serviceurl;   //配置接口地址39.104.180.16
axios.defaults.withCredentials=true;//配置携带cookie
//qs.stringify()是将对象 序列化成URL的形式，以&进行拼接
//  let protocol = window.location.protocol; //协议
//  let host = window.location.host; //主机
//  axios.defaults.baseURL = protocol + "//" + host;
 
axios.interceptors.request.use( //响应拦截
        async config => {
            // 每次发送请求之前判断vuex中是否存在token        
            // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
            // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断 
            let token = window.sessionStorage.getItem("accessToken");
            if (token) {
                //将token放到请求头发送给服务器,将tokenkey放在请求头中
                config.headers.token = token;
                //也可以这种写法
                // config.headers['accessToken'] = Token;
            }
            if(config.method  === 'post'  && config.headers['Content-Type'] === 'application/json'){
                config.data = QS.stringify(config.data);
            }
            return config;
        },
        error => {
            return Promise.error(error);
        })
    // 响应拦截器
axios.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return Promise.resolve(response); //进行中        
        } else {
            return Promise.reject(response); //失败       
        }
    },
    // 服务器状态码不是200的情况    
    error => {
        return Promise.reject(error);
    }
);
/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
const $fetchGet = (url, params) => {
        return new Promise((resolve, reject) => {
            axios.get(url, {params: params})
            .then(response => {
                resolve(response)
            }, err => {
                reject(err)
            })
            .catch((error) => {
                reject(error)
            })
        });
    }
    /** 
     * post方法，对应post请求 
     * @param {String} url [请求的url地址] 
     * @param {Object} params [请求时携带的参数] 
     */
const $fetchPost = (url, params) => {
        return new Promise((resolve, reject) => {
            axios.post(url, params)
            .then(response => {
                resolve(response);
            }, err => {
                reject(err);
            })
            .catch((error) => {
                reject(error)
            })
        });
    }
    //下面是vue3必须加的，vue2不需要，只需要暴露出去get，post方法就可以
export default {
    install: (app) => {
        app.config.globalProperties['$fetchGet'] = $fetchGet;
        app.config.globalProperties['$fetchPost'] = $fetchPost;
        app.config.globalProperties['$axios'] = axios;
    }
}