<template>
  <div class="container" style="width:100%;height:100%">
    <h1 style="position: relative;top: 3%;">产品介绍</h1>
    <h3 style="position: relative;top: 5.5%;color:#5C5C5C">Product introduction</h3>
    <span style="position: relative;top: 5.5%;display:inline-block;width:4%;height:0.5%;background:#00A8FF"></span>
    <div style="position: relative;top: 9.5%;left:25%;cursor:pointer;display:flex;align-items: center;background: #00a8ff;border-radius: 29px;width:50%;height:6.5%;padding: 5px;">
      <!-- <span style="flex:1;display:inline-block;height:100%;border-radius: 29px;line-height: 50px" @click="clickMenu(1)" :style="{background:active==1?'#00A8FF':' #00a8ff',color:active==1?'#fff':'#000'}">东方丝路</span>  -->
      <span style="flex:1;display:inline-block;height:100%;border-radius: 29px;line-height: 50px" @click="clickMenu(1)" :style="{background:active==1?'#fff':' #00a8ff',color:active==1?'#00a8ff':'#fff'}">地理信息引擎</span>  
      <!-- <span style="flex:1;display:inline-block;height:100%;border-radius: 29px;line-height: 50px" @click="clickMenu(2)" :style="{background:active==2?'#00A8FF':' #00a8ff',color:active==2?'#fff':'#000'}">大数据中台</span>  -->
      <span style="flex:1;display:inline-block;height:100%;border-radius: 29px;line-height: 50px" @click="clickMenu(2)" :style="{background:active==2?'#fff':' #00a8ff',color:active==2?'#00a8ff':'#fff'}">数字孪生引擎</span>  
      <span style="flex:1;display:inline-block;height:100%;border-radius: 29px;line-height: 50px" @click="clickMenu(5)" :style="{background:active==5?'#fff':' #00a8ff',color:active==5?'#00a8ff':'#fff'}">大模型引擎</span>  
      <!-- <span style="flex:1;display:inline-block;height:100%;border-radius: 29px;line-height: 50px" @click="clickMenu(3)" :style="{background:active==3?'#00A8FF':' #00a8ff',color:active==3?'#fff':'#000'}">物联网中台</span>  -->
      <span style="flex:1;display:inline-block;height:100%;border-radius: 29px;line-height: 50px" @click="clickMenu(3)" :style="{background:active==3?'#fff':' #00a8ff',color:active==3?'#00a8ff':'#fff'}">大数据中台</span>  
      <!-- <span style="flex:1;display:inline-block;height:100%;border-radius: 29px;line-height: 50px" @click="clickMenu(4)" :style="{background:active==4?'#00A8FF':' #00a8ff',color:active==4?'#fff':'#000'}">人工智能</span>  -->
      <span style="flex:1;display:inline-block;height:100%;border-radius: 29px;line-height: 50px" @click="clickMenu(4)" :style="{background:active==4?'#fff':' #00a8ff',color:active==4?'#00a8ff':'#fff'}">物联网中台</span>  
    </div>
    <div style="width:100%;height:75%;position: relative;top: 15%;">
      <img style="width:100%;height:100%" :src="activeImg">
       <el-button @click="toPath()" type="primary" round style="backgound:#00A8FF;position: relative;top: -15%;">
        <span style="vertical-align: middle" > 了解更多&nbsp; </span>
         <el-icon style="vertical-align: middle">
          <Right />
        </el-icon>
      </el-button>
    </div>
  </div>
</template>

<script setup>
  import {ref,getCurrentInstance,defineEmits} from 'vue'
  import url1 from '@/assets/img/1东方丝路.png'
  import url2 from '@/assets/img/2大数据中台.png'
  import url3 from '@/assets/img/3物联网中台.png'
  import url4 from '@/assets/img/4人工智能.png'
  import url5 from '@/assets/img/5大模型引擎.png'
  import {Right} from '@element-plus/icons-vue'
  let active=ref(1)
  let activeImg=ref(url1)
  const { proxy } = getCurrentInstance();
  const emit = defineEmits(['toPath'])
  function clickMenu(val) {
    active.value=val
    if (val==1) {
      activeImg.value=url1
    }else if (val==2) {
      activeImg.value=url2  
    }else if (val==3) {
      activeImg.value=url3  
    }else if (val==4) {
      activeImg.value=url4  
    }else if (val==5) {
      activeImg.value=url5  
    }
  }
  function toPath() {
    if (active.value==1) {
      emit('toPath','first');
    }else if (active.value==2){
      emit('toPath','second');
    }else if (active.value==3){
      emit('toPath','third');
    }else if (active.value==4){
      emit('toPath','fourth');
    }else if (active.value==5){
      emit('toPath','fifth');
    }
  }

</script>

<style scoped>
.container {
  background: url('../../assets/img/产品中心背景.png');
  background-size: 100% 100%;
  background-repeat:no-repeat ;
}
</style>