<template>
  <div class="container" style="width:100%;height:100%">
    <div style="position:relative;width:65%;height:100%;margin-left:17.5%;color:#000000;">
      <h1 style="position: relative;top: 5%;">合作伙伴</h1>
      <h3 style="position: relative;top: 5.5%;color:#5C5C5C">Cooperative partner</h3>
      <span style="position: relative;top: 4.5%;display:inline-block;width:4%;height:0.5%;background:#00A8FF"></span>
      <div style="width:100%;height:78%;position: relative;top: 8%;">
        <el-carousel class="carousels" arrow="always" indicator-position="outside">
          <el-carousel-item style="width:100%;height:100%"  v-for="(item,index) in imgList" :key="index">
            <img style="width:100%;height:100%" :src="item.url">
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue'
import url1 from '@/assets/img/合作伙伴1.png'
import url2 from '@/assets/img/合作伙伴2.png'
export default {
  setup () {
    let imgList=ref([
      {
        url:url1  
      },
      {
        url:url2
      }
    ])
    return {
      imgList
    }
  }
}
</script>

<style scoped>
.container {
  background: url('../../assets/img/合作伙伴背景.png');
  background-size: 100% 100%;
  background-repeat:no-repeat ;
}

</style>
<style>
.carousels {
  width: 100%;
  height: 100% ;
}
.carousels .el-carousel__container {
  width: 100%;
  height: 97% !important; /* 设置为父元素高度的50% */
}
</style>