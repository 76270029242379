import Footers from '../../Footers/Footers.vue'
import {Right,Back} from '@element-plus/icons-vue'
import {ref,reactive, defineComponent,getCurrentInstance, watch} from 'vue'
export default defineComponent({
  components:{
    Footers,
    Right,
    Back
  },
//   props:['productActive'],
  props: {
    productActive: String
  },
  setup (props) {
    const { proxy } = getCurrentInstance();
    let activeName=ref('')
    let shows=ref(true)
		let data=ref([
        {
        // name:'东方丝路',
        // content:'“东方丝路”孪生引擎“Orient Silk”：是“东方丝路”近十年探索、研究和开发的数字孪生引擎软件，实现了全域国产、自主知识产权、三维可视化、IoT虚实联动，提供从数据、平台和行业应用完整的数字孪生应用解决方案。公司掌握了“数字孪生”领域的核心技术，已经为近500个“数字孪生”项目提供了技术支持和解决方案。',
        name:'地理信息引擎',
				content:'\t地理信息引擎（Magic GIS）能够对庞大的地理数据集进行有效地组织、存储与管理，这些数据集包括地形地貌、林草分布、水系分布、交通网络等各类地理要素。此外，它还能够对这些分散且复杂的地理数据执行规范化和标准化的处理流程。\n\t地理信息引擎拥有将地理数据可视化呈现的卓越能力，它通过将抽象的数据转化为直观的地图和图表等形式，使得人们能够更加便捷地理解和解读地理信息。此外，该引擎还能够执行复杂的空间分析和数据挖掘任务，以揭示地理现象背后的规律性和趋势性。',
        img:require('@/assets/img/1东方丝路.png')
      },
       {
        // name:'大数据中台',
        // content:'“东方丝路”大数据中台是公司拥有完全自主知识产权和核心技术的一款产品，具备丰富的数据模型和数据产品、强大的算法服务和数据管理能力；包括数据汇聚整合、数据提纯加工、数据服务可视化、数据价值挖掘4大核心能力。\n“东方丝路”大数据中台可以通过公司独特的空间大数据技术，对各类海量数据进行采集、计算、存储、加工，同时统一标准和口径，形成各类标准数据，再进行存储，形成政企大数据资产层，进而为客户提供高效便捷的各类服务。\n“东方丝路”大数据中台持续助力政企客户构建高效的数据中台，让更多的数据持续走向业务、赋能业务，赋能政企客户成功数字化转型。',
        name:'数字孪生引擎',
				content:'\t数字孪生引擎（Clone Space）是我公司自主研发的先进渲染引擎，该引擎以三维建模技术为核心，深度整合了领先的AI、物联网、大数据等前沿技术，构建了一个具有高度复用性的数字孪生行业通用模型体系，支持场景的灵活定义。Clone Space专注于三维场景的构建与导出，支持在场景面板中导入，并可通过配置实现双向通信，显著提升了数字孪生应用开发与交付的效率。\n\tClone Space 将模型文件、散点、路径、数据面板等时空数据要素在可视化表达中进行了优化整合，实现了环绕镜头、多点巡航的一键生成。在渲染引擎内部，Clone Space 即可实现数字孪生场景中的动态传值，为数字孪生城市的环境调试、场景渲染配置提供了便捷的工具，材质纹理、天光环境等设置一触即达。',
        img:require('@/assets/img/2大数据中台.png')
      },
       {
        name:'大模型引擎',
				content:'\t作为企业智能创新的核心平台，大模型引擎致力于打造一个全面的人工智能大模型全生命周期管理平台及服务体系。',
				img:require('@/assets/img/5大模型引擎.png')
      }, 
      {
        // name:'物联网中台',
        // content:'“东方丝路”物联网中台是一个面向物联网智能化提供的应用开发平台，可以为公司数字孪生引擎和大数据中台提供基于物联网的各类数据；它涵盖IoT智能化的核心应用场景和丰富的能力组件可以将工业现场设备、仪器仪表互联集成，提供即时、安全的数据采集、数据分析及可视化展示，为企业提供产品工艺分析优化、能源优化管控、设备运维管理、生产能效提升等数字化服务，助其实现有效价值的数字化转型与升级。',
        name:'大数据中台',
				content:'\t大数据中台，构筑于云计算、大数据以及人工智能等前沿技术之上，对企业数据资产执行了详尽而系统的梳理工作。这一过程确保了数据资产能够精准地响应并满足业务需求，进而显著提升企业的运营效率和市场竞争力。\n\t大数据中台为企业构筑了一条坚实的数据驱动发展道路，其强大的数据处理能力、高效的数据整合能力、精准的数据分析能力以及创新的数据可视化能力，均成为企业实现数据驱动业务转型的核心动力，大数据模型的开发和集成是该功能不断强大的源动力。',
        img:require('@/assets/img/3物联网中台.png')
      }, 
      {
        // name:'人工智能',
        // content:'“东方丝路人工智能”是基于计算机知识、图形学、地理学、心理学和哲学等多学科交叉融合而研发的一款极具创新和科技含量的尖端产品，可以广泛应用于模拟、延伸和扩展人的智能的理论、方法、技术及各类应用，在智慧警务、智慧交通、智慧监狱、智慧矿山等行业得到了广泛深入的应用，尤其是在语言识别、图像识别、人脸识别等应用场景中，均发挥了重要作用。',
        name:'物联网中台',
				content:'\t物联网数据中台，作为一项融合了硬件设备、软件平台、通信网络等多方面要素的先进智能化系统架构，展现出了卓越的适用性。它不仅限于支持智慧城市、智慧交通、智慧警务等单一应用场景，而是能够广泛应用于多种多元化场景之中。\n\t该平台为企业构筑了一块高效、安全、稳固的数据管理基石，显著提升了企业的运营效率和创新能力的拓展。它成功地将企业内部分散的生产设备和数据资源进行了整合，实现了集中管理和深度分析，进而为企业提供了更为精确的数据洞察力。此外，物联网数据中台还支持数据可视化和实时监控功能，使企业能够实时了解生产状况，为科学决策提供了坚实的支持。在数字化转型的浪潮中，物联网数据中台无疑是企业实现高效运营和创新发展的有力工具。\n\t物联网数据中台在设备管理与维护方面也展现了其卓越能力。通过设备故障预警、远程控制、远程维护等功能的实现，显著提升了设备的运行效率与使用寿命。该平台能够集成多种类型的数据（如 PLC、仪器仪表、CNC、机器人等），并运用先进的智能算法与大数据分析技术，进行深度优化分析与预测，为企业提供了更为精准、科学的业务决策支持。',
        img:require('@/assets/img/4人工智能.png')
      }
    ])
    let activeObject=reactive({})
    let productActives=ref('first')
    productActives.value=props.productActive
    watch(() => productActives, (newValue, oldValue) => {
      if (newValue.value!='') {
        if (newValue.value=='first') {
          // activeName.value='东方丝路'
          activeName.value='地理信息引擎'
        }else if (newValue.value=='second'){
          // activeName.value='大数据中台'
          activeName.value='数字孪生引擎'
        }else if (newValue.value=='third'){
          // activeName.value='物联网中台'
          activeName.value='大数据中台'
        }else if (newValue.value=='fourth'){
          // activeName.value='人工智能'
          activeName.value='物联网中台'
        }else if (newValue.value=='fifth'){
          // activeName.value='人工智能'
          activeName.value='大模型引擎'
        }
        shows.value=false
        for (var i=0;i<data.value.length;i++) {
          if (activeName.value==data.value[i].name) {
            Object.assign(activeObject,data.value[i])
          }
        }
      }
    },{
      immediate: true,
    });
    function details(val) {
      activeName.value=val
      let scrollElem = this.$refs.scrollDiv;
      scrollElem.scrollTo({ top: 0, behavior: 'smooth' });
      shows.value=false
      for (var i=0;i<data.value.length;i++) {
        if (activeName.value==data.value[i].name) {
          Object.assign(activeObject,data.value[i])
        }
      }
     
    }
    function activeMenu(val) {
      activeName.value=val
      for (var i=0;i<data.value.length;i++) {
        if (activeName.value==data.value[i].name) {
            Object.assign(activeObject,data.value[i])
          }
      }
    }
    function toBack() {
      shows.value=true
      let scrollElem = this.$refs.scrollDiv;
      scrollElem.scrollTo({ top: 0, behavior: 'smooth' });    
    }
    return {
      activeName,
      shows,
      data,
      activeObject,
      productActives,
      details,
      activeMenu,
      toBack
    }
  }
})