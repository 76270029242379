<template>
  <div style="width:100%;height:100%;overflow-y: scroll;">
    <swiperItem style="width:100%;margin-left:0%;height:100%"></swiperItem>
    <BriefIntroduction class="boxImgBriefIntroduction" @toIntro="getIntro" style="width:100%;height:66%;"></BriefIntroduction>
    <ProductIntroduction @toPath="getPath" style="width:100%;height:90%;"></ProductIntroduction>
    <DynamicNews  class="boxImgDynamicNews" @toNews="getNews" style="width:100%;height:85%"></DynamicNews>
    <CooperativePartner style="width:100%;height:80%;"></CooperativePartner>
    <Footers style="width:100%;height:27%;margin-top:-1%"></Footers>
  </div>
</template>

<script src="./js/HomePage.js">
</script>

<style src="./css/HomePage.css" scoped>

</style>