<template>
   <el-footer style="width:100%;height:100%;background: #132146;">
          <div style="position:relative;width:65%;height:100%;margin-left:17.5%;color:#fff;">
            <!-- <div style="position:relative;top:2.5%;width:70%;height:20%;display:flex;align-items: center;font-weight:600;text-align:left">
              <span style="flex:1">首页</span>
              <span style="flex:1">解决方案</span>
              <span style="flex:1">产品中心</span>
              <span style="flex:1">招贤纳士</span>
              <span style="flex:1">公司简介</span>
            </div> -->
            <ul style="position:relative;top:18%;text-align:left;height:68%;">
              <li style="margin-top: 1.2%;">
                <span>公司名称：</span>
                <span>东方丝路（西安）数字技术有限责任公司</span>
              </li>
              <li>
                <span>公司地址：</span>
                <span>陕西省西安市高新区丈八六路34号云南城投A座1405室</span>
              </li>
              <li>
                <span>公司邮箱：</span>
                <span>clonespace@clonespace.com.cn</span>
              </li>
              <li>
                <span>联系电话：</span>
                <span>029 - 81145903</span>
              </li>
            </ul>
            <img style="position:absolute;top:22%;right:0;width: 120px;" src="../../assets/img/二维码.png">
            <span style="position:absolute;top:79%;right:0;font-size:13px;color:#737D92">关注东方丝路公众号</span>
          </div>
        </el-footer>
</template>

<script>
export default {

}
</script>

<style scoped>
ul li {
  list-style: none;
  margin-top: 1%;
}
</style>