import {ref,defineComponent,defineEmits} from 'vue'
import swiperItem from '../swiper.vue'
//公司简介
import BriefIntroduction from '../BriefIntroduction.vue'
import Footers from '../../Footers/Footers.vue'
//产品介绍
import ProductIntroduction from '../ProductIntroduction.vue'
//动态资讯
import DynamicNews from '../DynamicNews.vue'
//合作伙伴
import CooperativePartner from '../CooperativePartner.vue'
export default defineComponent({
  components:{
    swiperItem,
    BriefIntroduction,
    Footers,
    ProductIntroduction,
    DynamicNews,
    CooperativePartner
  },
  setup(props, { emit }) {
    let activePath=ref('')
    function getPath(val) {
      activePath.value=val
      emit('productActive',activePath.value)
    }
		function getIntro(val) {
		  activePath.value=val
		  emit('introActive',activePath.value)
		}
		function getNews(val) {
		  activePath.value=val
		  emit('newsActive',activePath.value)
		}
    return {
      getPath,
			getIntro,
			getNews
    }
  }
})