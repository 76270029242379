import Footers from '../../Footers/Footers.vue'
import {ref, onMounted} from 'vue'
import {Phone,Message} from '@element-plus/icons-vue'
import axios from 'axios'; 
export default {
  components:{
    Footers,
    Phone,
    Message
  },
	
  setup() {
    let data=ref([
      {
        job:'前端开发工程师',
        describeList:[
          {
            text:'参与物联网可视化产品或项目的需求讨论，相关功能的设计，按照敏捷迭代流程，参与团队的故事拆分、任务的认、开发及单测的编写及后续的维护、文档编写、CodeReview；'
          },
          {
            text:'对公司组件库的开发维护及新技术新方案的探索并顺利落实到项目或产品中；'
          },
          {
            text:'页面功能开发，配合交互视觉，完成设计稿高还原度的实现；'
          }, 
          {
            text:' 参与前端标准化，探索前端新技术并落地实践；'
          }, 
          {
            text:'  负责组件的编写维护，新技术探索研究，组间的技术对接。'
          }
        ],
        requirementList:[
          {
            text:' 计算机相关专业，5年以上web前端开发经验，如果你足够优秀，可放宽条件；'
          },
          {
            text:' 熟悉W3C标准，熟悉HTML5；'
          },
          {
            text:'至少会一种CSS预处理语言，如LESS，SASS，STYLUS等；'
          },
          {
            text:'熟练掌握原生JS，JS基础扎实；'
          },
          {
            text:'熟练掌握Vue全家桶，Element UI；'
          }
        ]
      },
      {
        job:'GIS开发工程师',
        describeList:[
            {
              text:'负责三维GIS开发工作；'
            },
            {
              text:' 熟悉cesium开发，从事数据可视化类相关GIS开发工作；'
            },
            {
              text:'根据开发需求，进行相关软件开发工作；'
            }, 
            {
              text:' 进行开发文档的编写工作；'
            }, 
            {
              text:'具备良好的沟通能力；'
            }
          ],
          requirementList:[
            {
              text:'本科及以上学历，地理信息系统、计算机、数学及相关专业毕业；'
            },
            {
              text:'能独立完成项目功能模块设计开发；'
            },
            {
              text:' 熟练掌握Java开发语言及MySQL、Oracle、Sqlserver数据库；'
            },
            {
              text:'具备GIS领域相关专业知识或有cesium引擎相关开发或使用经验者优先；'
            },
            {
              text:'具备WEBGL相关技能者优先；'
            },
            {
              text:'具备较强的团队合作精神、工作责任心和执行能力。'
            }
          ]
      },
      {
        job:'售前工程师',
        describeList:[
            {
              text:'学习公司产品，进行产品讲解；'
            },
            {
              text:'信息化建设项目方案编写；'
            },
            {
              text:'协助商务进行项目分析和产品讲解。'
            }, 
          ],
          requirementList:[
            {
              text:' 具有较强的软件系统方案梳理和汇报材料编写能力；'
            },
            {
              text:'统招本科以上学历；学历不符合不要投递'
            },
            {
              text:'中文、演讲主持、计算机相关专业优先；'
            },
            {
              text:'形象气质佳，自信、律己。'
            }
          ]
      },
    ])
    let dialogVisible=ref(false)
    function joinwe() {
      dialogVisible.value=true  
    }
		// 发起GET请求  
		const fetchData = async () => {  
		  try {  
		    const response = await axios.get('/Recruitment/findAll');
				console.log(response);
		    if (response.status === 200) {
		      console.log(response.data);
		      data.value = response.data;
		    } else {
		      console.error(response.statusText);
		    }
		  } catch (error) {
		    console.error('请求失败:', error);
		  }
		};
		onMounted(() => {  
		  fetchData();  
		}); 
    return {
      data,
      dialogVisible,
      joinwe
    }
  }
}