<template>
  <div class="container" style="width:100%;height:100%">
    <div style="position:relative;width:65%;height:100%;margin-left:17.5%;top:14%;color:#000000;">
      <h1 style="position: relative;top: 2%;">动态资讯</h1>
      <h3 style="position: relative;top: 3.5%;color:#5C5C5C">Dynamic information</h3>
      <span style="position: relative;top: 3.5%;display:inline-block;width:4%;height:0.5%;background:#00A8FF"></span>
      <div style="width:100%;height:56%;position:relative;top:8%;display:flex">
        <div @click="toNews(tableDataLeft.id)" style="flex:0.9;text-align: left; box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);">
          <!-- <img style="width:96%;height:77%;margin-top:2%;margin-left:2%" src="../../assets/img/资讯1.png"> -->
          <img style="width:96%;height:67%;margin-top:2%;margin-left:2%" :src="tableDataLeft.imageUrl">
          <!-- <h3 style="margin-top:2%;;margin-left:2%">数字孪生迈向“技术+应用”双驱动时代</h3> -->
          <h3 class="title" style="margin-top:2%;;margin-left:2%">{{tableDataLeft.title}}</h3>
          <p class="content" style="width:90%;margin-left:2%;margin-top:2%;color: #666666;line-height:25px;">{{tableDataLeft.content}}</p>
        </div>   
        <div style="flex:1;margin-left:1%;display:flex;flex-direction: column;">
          <div @click="toNews(tableDataRight1.id)" style="flex:1;display:flex;text-align:left; box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);">
            <!-- <img style="flex:0.7;width:21%;height:84%;margin-top:2%;margin-left:2%" src="../../assets/img/资讯2.png"> -->
            <img style="flex:0.7;width:25%;height:84%;margin-top:2%;margin-left:2%" :src="tableDataRight1.imageUrl">
            <div style="margin-top:1%">
              <h3 class="title" style="flex:1.5;margin-top:1%;margin-left:3%">{{tableDataRight1.title}}</h3>
              <p class="content" style="flex:1.5;margin-top:3%;margin-left:3%;color: #666666;line-height:25px;">{{tableDataRight1.content}}</p>
            </div>
          </div>
          <div @click="toNews(tableDataRight2.id)" style="flex:1;margin-top:2%;display:flex;text-align:left; box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);">
            <!-- <img style="flex:0.7;width:21%;height:84%;margin-top:2%;margin-left:2%" src="../../assets/img/资讯3.jpg"> -->
            <img style="flex:0.7;width:25%;height:84%;margin-top:2%;margin-left:2%" :src="tableDataRight2.imageUrl">
            <div style="margin-top:1%">
              <h3 class="title" style="flex:1.5;margin-top:1%;margin-left:3%">{{tableDataRight2.title}}</h3>
              <p class="content" style="flex:1.5;margin-top:3%;margin-left:3%;color: #666666;line-height:25px;">{{tableDataRight2.content}}</p>
            </div>
          </div>
          <div @click="toNews(tableDataRight3.id)" style="flex:1;margin-top:2%;display:flex;text-align:left; box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);">
            <!-- <img style="flex:0.7;width:21%;height:84%;margin-top:2%;margin-left:2%" src="../../assets/img/资讯4.png"> -->
            <img style="flex:0.7;width:25%;height:84%;margin-top:2%;margin-left:2%" :src="tableDataRight3.imageUrl">
            <div style="margin-top:1%">
              <h3 class="title" style="flex:1.5;margin-top:1%;margin-left:3%">{{tableDataRight3.title}}</h3>
              <p class="content" style="flex:1.5;margin-top:3%;margin-left:3%;color: #666666;line-height:25px;">{{tableDataRight3.content}}</p>
            </div>
          </div>
        </div>  
      </div>
     </div>
  </div>
</template>

<script setup>
	import { ref,onMounted } from 'vue'
	import axios from 'axios'; 
	let tableData=ref([])
	let tableDataLeft=ref({})
	let tableDataRight1=ref({})
	let tableDataRight2=ref({})
	let tableDataRight3=ref({})
	const emit = defineEmits(['toNews'])

	// 发起GET请求
	const fetchData = async () => {
	  try {
	    const response = await axios.get(`/news/queryNewestNewsForMore`);
			console.log(response);
	    if (response.status === 200) {
				if (response.data.code === 200) {
					console.log(response.data.data);
					tableData.value = response.data.data.resultSet
					tableDataLeft.value = response.data.data.resultSet[0]
					tableDataRight1.value = response.data.data.resultSet[1]
					tableDataRight2.value = response.data.data.resultSet[2]
					tableDataRight3.value = response.data.data.resultSet[3]
				} else {
					console.error(response.data.message);
				}
	    } else {
	      console.error(response.statusText);
	    }
	  } catch (error) {
	    console.error('请求失败:', error);
	  }
	};
	onMounted(() => {
	  fetchData();
	});
	
	function toNews(val) {
		emit('toNews',val);
	}
	
</script>

<style scoped> 
	.title {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1; /* 设置最大行数 */
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.content {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2; /* 设置最大行数 */
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>