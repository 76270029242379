<template>
   <div ref="scrollDiv" style="width:100%;height:100%;overflow-y:scroll;position:relative">
    <div style="width:100%;height:55%;">
      <img style="width:100%;height:100%" src="@/assets/img/产品中心.png">
    </div>
    <div v-if="shows" style="width:100%;height:100%;position:relative;">
      <div style="width:100%;height:50%;position:relative;">
      <div style="width:65%;position:relative;left:17.5%;">
         <div style="width:100%;display:flex;text-align:left">
          <div style="flex:1">
            <img style="width:81%;height:102%;margin-top: 5.3%;" src="@/assets/img/产品中心东方丝路.png">
          </div>
          <div style="padding-bottom: 1.5%;flex:1;text-align:left;margin-top:1.5%;line-height:30px;color: #000000;">
            <img style="height:26%;margin-top: 5.3%;" src="@/assets/img/产品中心东方丝路01.png">
            <!-- <p style="margin-top: 2.3%;">适用智慧园区或某一个行业应用的小场景应用。</p> -->
						<!-- <el-button @click="details('东方丝路')" type="primary" round style="backgound:#00A8FF;position: relative;top: 48%;"> -->
            <p style="margin-top: 2.3%;">地理信息引擎（Magic GIS）能够对庞大的地理数据集进行有效地组织、存储与管理。</p>
            <el-button @click="details('地理信息引擎')" type="primary" round style="backgound:#00A8FF;position: relative;top: 37%;">
              <span style="vertical-align: middle" > 了解更多&nbsp; </span>
              <el-icon style="vertical-align: middle">
                <Right />
              </el-icon>
            </el-button>
          </div>
        </div>
      </div> 
      </div>
      <div style="width:100%;height:50%;background: #F4F6FB;position:relative;">
      <div style="width:65%;position:relative;left:17.5%;">
        <div style="width:100%;display:flex;text-align:left">
          <div style="padding-bottom: 1.5%;flex:1;text-align:left;margin-top:1.5%;line-height:30px;color: #000000;">
            <img style="height:26%;margin-top: 5.3%;" src="@/assets/img/产品中心大数据中台02.png">
            <!-- <p style="margin-top: 2.3%;width:81%">在数据仓库和大数据平台的基础上，将数据生产为为一个个数据 API 服务，以更高效的方式提供给业务,本质是一个构建在数据仓库之上的跨业务主题的业务系统。</p> -->
            <!-- <el-button  @click="details('大数据中台')" type="primary" round style="backgound:#00A8FF;position: relative;top: 29%;"> -->
						<p style="margin-top: 2.3%;width:81%">数字孪生引擎（Clone Space）专注于三维场景的构建与导出，支持在场景面板中导入，并可通过配置实现双向通信，显著提升了数字孪生应用开发与交付的效率。</p>
              <el-button  @click="details('数字孪生引擎')" type="primary" round style="backgound:#00A8FF;position: relative;top: 28%;">
              <span style="vertical-align: middle" > 了解更多&nbsp; </span>
              <el-icon style="vertical-align: middle">
                <Right />
              </el-icon>
            </el-button>
          </div>
          <div style="flex:1">
            <img style="width:81%;height:102%;margin-top: 5.3%;" src="@/assets/img/产品中心大数据中台.png">
          </div>
        </div>
      </div>  
      </div>
      <div style="width:100%;height:50%;position:relative;">
      <div style="width:65%;position:relative;left:17.5%;">
        <div style="width:100%;display:flex;text-align:left">
          <div style="flex:1">
            <img style="width:81%;height:102%;margin-top: 5.3%;" src="@/assets/img/产品中心物联网中台.png">
          </div>
          <div style="padding-bottom: 1.5%;flex:1;text-align:left;margin-top:1.5%;line-height:30px;color: #000000;">
            <img style="height:26%;margin-top: 5.3%;" src="@/assets/img/产品中心物联网中台03.png">
            <!-- <p style="margin-top: 2.3%;">物联中台相对于数据中台层次上更为抽象和高级，通常包含了采集平台、通信中台和数据中台的全部特性，除了支持数据分析、处理、交易等抽象业务服务外，还涉及采集和通信。</p> -->
            <!-- <el-button  @click="details('物联网中台')" type="primary" round style="backgound:#00A8FF;position: relative;top: 31%;"> -->
						<p style="margin-top: 2.3%;">作为企业智能创新的核心平台，大模型引擎致力于打造一个全面的人工智能大模型全生命周期管理平台及服务体系。</p>
              <el-button  @click="details('大模型引擎')" type="primary" round style="backgound:#00A8FF;position: relative;top: 37%;">
              <span style="vertical-align: middle" > 了解更多&nbsp; </span>
              <el-icon style="vertical-align: middle">
                <Right />
              </el-icon>
            </el-button>
          </div>
        </div>
      </div> 
      </div>
      <div style="width:100%;height:50%;background: #F4F6FB;position:relative;">
      <div style="width:65%;position:relative;left:17.5%;">
        <div style="width:100%;display:flex;text-align:left">
          <div style="padding-bottom: 1.5%;flex:1;text-align:left;margin-top:1.5%;line-height:30px;color: #000000;">
            <img style="height:26%;margin-top: 5.3%;" src="@/assets/img/产品中心人工智能04.png">
            <!-- <p style="margin-top: 2.3%;width:81%">在数据仓库和大数据平台的基础上，将数据生产为为一个个数据 API 服务，以更高效的方式提供给业务,本质是一个构建在数据仓库之上的跨业务主题的业务系统。</p> -->
            <!-- <el-button  @click="details('人工智能')" type="primary" round style="backgound:#00A8FF;position: relative;top: 30%;"> -->
						<p style="margin-top: 2.3%;width:81%">大数据中台（Clone BT）构筑于云计算、大数据以及人工智能等前沿技术之上，对企业数据资产执行了详尽而系统的梳理工作。</p>
              <el-button  @click="details('大数据中台')" type="primary" round style="backgound:#00A8FF;position: relative;top: 28%;">
              <span style="vertical-align: middle" > 了解更多&nbsp; </span>
              <el-icon style="vertical-align: middle">
                <Right />
              </el-icon>
            </el-button>
          </div>
          <div style="flex:1">
            <img style="width:81%;height:102%;margin-top: 5.3%;" src="@/assets/img/产品中心人工智能.png">
          </div>
        </div>
      </div>   
      </div>
			<div style="width:100%;height:50%;position:relative;">
			<div style="width:65%;position:relative;left:17.5%;">
			  <div style="width:100%;display:flex;text-align:left">
			    <div style="flex:1">
			      <img style="width:81%;height:102%;margin-top: 5.3%;" src="@/assets/img/产品中心物联网.png">
			    </div>
			    <div style="padding-bottom: 1.5%;flex:1;text-align:left;margin-top:1.5%;line-height:30px;color: #000000;">
			      <img style="height:26%;margin-top: 5.3%;" src="@/assets/img/产品中心物联网05.png">
			      <!-- <p style="margin-top: 2.3%;">物联中台相对于数据中台层次上更为抽象和高级，通常包含了采集平台、通信中台和数据中台的全部特性，除了支持数据分析、处理、交易等抽象业务服务外，还涉及采集和通信。</p> -->
			      <!-- <el-button  @click="details('物联网中台')" type="primary" round style="backgound:#00A8FF;position: relative;top: 31%;"> -->
						<p style="margin-top: 2.3%;">物联网数据中台（Clone LoT）作为一项融合了硬件设备、软件平台、通信网络等多方面要素的先进智能化系统架构，展现出了卓越的适用性。</p>
			        <el-button  @click="details('物联网中台')" type="primary" round style="backgound:#00A8FF;position: relative;top: 37%;">
			        <span style="vertical-align: middle" > 了解更多&nbsp; </span>
			        <el-icon style="vertical-align: middle">
			          <Right />
			        </el-icon>
			      </el-button>
			    </div>
			  </div>
			</div> 
			</div>
       <Footers style="width:100%;height:27%;position:relative;"></Footers>
    </div>
    <div v-if="!shows" style="width:100%;height:100%;position:relative;">
      <div style="width:100%;height:5%;background: #F5F5F5;display:flex;align-items:center">
        <span style="position:relative;left:17.5%;color: #000000;">解决方案&nbsp;>&nbsp;{{activeName}}</span> 
        <span @click="toBack()" style="cursor:pointer;color:#409EFF;position:absolute;left:79%;">
          <el-icon style="vertical-align: middle">
            <Back />
          </el-icon>&nbsp;
          <span>返回</span>
        </span>
      </div>
      <div style="width:65%;height:6%;position:relative;left:17.5%;top:2%;color: #000000;margin-top:2%;cursor:pointer;background: #EDF1F7;display:flex;align-items:center">
        <!-- <span style="flex:1" :class="activeName=='东方丝路'?'active':'otherActive'" @click="activeMenu('东方丝路')">东方丝路</span> -->
        <span style="flex:1" :class="activeName=='地理信息引擎'?'active':'otherActive'" @click="activeMenu('地理信息引擎')">地理信息引擎</span>
        <!-- <span style="flex:1" :class="activeName=='大数据中台'?'active':'otherActive'" @click="activeMenu('大数据中台')">大数据中台</span> -->
        <span style="flex:1" :class="activeName=='数字孪生引擎'?'active':'otherActive'" @click="activeMenu('数字孪生引擎')">数字孪生引擎</span>
        <span style="flex:1" :class="activeName=='大模型引擎'?'active':'otherActive'" @click="activeMenu('大模型引擎')">大模型引擎</span>
        <!-- <span style="flex:1" :class="activeName=='物联网中台'?'active':'otherActive'" @click="activeMenu('物联网中台')">物联网中台</span> -->
        <span style="flex:1" :class="activeName=='大数据中台'?'active':'otherActive'" @click="activeMenu('大数据中台')">大数据中台</span>
        <!-- <span style="flex:1" :class="activeName=='人工智能'?'active':'otherActive'" @click="activeMenu('人工智能')">人工智能</span> -->
        <span style="flex:1" :class="activeName=='物联网中台'?'active':'otherActive'" @click="activeMenu('物联网中台')">物联网中台</span>
      </div>
      <div style="width:65%;height:100%;position:relative;top:2%;left:17.5%;">
        <h1 style="margin-top:3%;font-size:32px;">{{activeObject.name}}</h1>
        <p style="text-indent: 2em;white-space: pre-wrap;line-height:30px;color:#000;margin-top:3%;text-align:left">{{activeObject.content}}</p>
        <!-- <img style="width:100%;height:65%;margin-top:3%;" :src="activeObject.img"> -->
        <img style="width:100%;margin-top:3%;" :src="activeObject.img">
      </div>
       <Footers style="width:100%;height:27%;position:relative;"></Footers>
    </div>
  </div>
</template>

<script src="./js/ProductCenter.js">
</script>

<style src="./css/ProductCenter.css" scoped>

</style>