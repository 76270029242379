import { createRouter, createWebHistory } from 'vue-router'
import Main from '../components/Main/Main.vue'
const routes = [
  { 
    path: '/',
    name: 'Main',
    component: Main,
    redirect: '/Main',
    children:[{
      path: '/Main', 
      name: 'Main',
      component: Main,
    }]
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
