var moduleserverurl="http://localhost:8180";
// const serviceurl = 'http://192.168.1.40:10100/template';
const serviceurl = 'http://8.130.29.204:8092/companyWeb';
// const serviceurl='http://192.168.1.200:10010/knowledgePlatformWeb'
// const serviceurl = 'http://10.62.18.227:8280';
const arcgisUrl='http://192.168.1.200:6080';
var pushmapserverurl="http://192.168.1.200:2017";
// var pushmapserverurl="http://10.62.18.227:8280";
var MapServer="http://39.104.180.16:2080"
var syspar_mselobj="0";
export default {
  moduleserverurl,
  syspar_mselobj,
  serviceurl,
  pushmapserverurl,
  MapServer,
  arcgisUrl
}
