<template>
  <div style="width:100%;height:100%;overflow-y:scroll;position:relative">
    <div style="width:100%;height:55%;">
      <img style="width:100%;height:100%" src="@/assets/img/行业解决方案.png">
    </div>
    <div style="width:100%;height:5%;background: #F5F5F5;display:flex;align-items:center">
      <span style="position:relative;left:17.5%;color: #000000;">解决方案&nbsp;>&nbsp;{{activeName}}</span> 
    </div>
    <div style="width:65%;position:relative;left:17.5%;top:2%">
      <div style="width:100%;height:4%;color: #000000;margin-top:2%;cursor:pointer;background: #EDF1F7;display:flex;align-items:center">
        <span style="flex:1" :class="activeName=='智慧交通'?'active':'otherActive'" @click="activeMenu('智慧交通')">智慧交通</span>
        <span style="flex:1" :class="activeName=='智慧警务'?'active':'otherActive'" @click="activeMenu('智慧警务')">智慧警务</span>
        <!-- <span style="flex:1" :class="activeName=='智慧城市'?'active':'otherActive'" @click="activeMenu('智慧城市')">智慧城市</span> -->
        <span style="flex:1" :class="activeName=='智慧水务'?'active':'otherActive'" @click="activeMenu('智慧水务')">智慧水务</span>
        <span style="flex:1" :class="activeName=='智慧园区'?'active':'otherActive'" @click="activeMenu('智慧园区')">智慧园区</span>
        <span style="flex:1" :class="activeName=='智慧矿山'?'active':'otherActive'" @click="activeMenu('智慧矿山')">智慧矿山</span>
        <span style="flex:1" :class="activeName=='智慧电网'?'active':'otherActive'" @click="activeMenu('智慧电网')">智慧电网</span>
        <!-- <span style="flex:1" :class="activeName=='综合应急'?'active':'otherActive'" @click="activeMenu('综合应急')">综合应急</span> -->
        <span style="flex:1" :class="activeName=='智慧地震'?'active':'otherActive'" @click="activeMenu('智慧地震')">智慧地震</span>
        <span style="flex:1" :class="activeName=='智慧监狱'?'active':'otherActive'" @click="activeMenu('智慧监狱')">智慧监狱</span>
      </div>
    </div>
    <h1 style="margin-top:3.5%;">{{activeObject.name}}</h1>
    <p style="text-indent: 2em;line-height:40px;margin-left:15%;text-align:left;margin-top:2.5%;color: #000000;">{{activeObject.titleText}}</p>
    <div style="width:100%;position:relative;">
      <div style="width:100%;;position:relative;top:2%">
        <div style="width:65%;margin-top:6%;margin-left:17%;display:flex;margin-bottom:3%;">
          <div style="flex:1">
            <img style="width:61%;height:93%;margin-top: 2.3%;" :src="activeObject.img1">
          </div>
          <div style="padding-bottom: 1.5%;text-indent: 2em;flex:1;text-align:left;margin-top:1.5%;line-height:40px;color: #000000;display: flex;align-items: center;">{{activeObject.content1}}</div>
        </div>
        <div class="boxImg" v-if="activeObject.content2!=''" style="margin-top:-3%;width:100%;padding-bottom:4%;">
          <div  style="width:100%;margin-top:7.5%;display:flex">
            <div style="padding-bottom: 1.5%;text-indent: 2em;flex:1;margin-left: 16%;text-align:left;margin-top:1.5%;line-height:40px;color: #000000;display: flex;align-items: center;">{{activeObject.content2}}</div>
            <div style="flex:1">
              <img style="width:45%;height:76%;margin-top: 10.3%;margin-left: -18%;" :src="activeObject.img2">
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footers style="width:100%;height:27%;position:relative;margin-top:-1%"></Footers>
  </div>
</template>

<script src="./js/SolutionPage.js">
</script>

<style src="./css/SolutionPage.css" scoped>

</style>